.colorTab {
  color: black !important;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.card .card-header {
  padding: 4px 15px 0 !important;
  font-size: 16px !important;
}

h2,
.h2 {
  font-size: 1.5em;
  margin-bottom: 0px;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
  margin: 0px !important;
  background-color: #ffffff !important;
  border: 1px solid #dddddd !important;
  border-radius: 4px !important;
  color: #66615b !important;
  line-height: normal !important;
  font-size: 14px !important;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 40px !important;
}

.content {
  padding: 15px 15px !important;
  margin-top: 63px !important;
}

.main-panel {
  background-color: white !important;
}

.popoverButton {
  border: none !important;
  background: #0f1d37 !important;
  color: white !important;
}
.popoverButton:hover {
  background: #1f335a !important;
}

.card-Header {
  background-color: #0f1d37;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.MuiTypography-h6 {
  font-size: 20px !important;
}
h6,
.h6 {
  text-transform: none;
}

.close {
  color: white !important;
}

/* .MTableToolbar-highlight-192 {
  color: #0f1d37 !important;
} */

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 3px 7px !important;
  vertical-align: middle !important;
}
/* Table entryMaterialTools and exitsMaterialTools */
.options-table {
  margin-left: 5px;
  text-align: center;
  cursor: pointer;
}

.delete-options :hover {
  background-color: red;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4a769b;
  color: white;
}
