.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-form {
  width: 40rem;
  max-width: 100%;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  margin-left: 12%;
}

.login-containerrr {
  margin: 0 auto;
  padding: auto;
  background: url(../img/assiscontrolLogin.jpg) no-repeat center center fixed;
  background-image: no-repeat;
  background-image: fixed;
  background-image: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.containerForm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
}

.buttonLogin {
  background-color: #262471;
  width: 100%;
}
.buttonLogin:hover {
  background-color: #3d3b80 !important;
}
.buttonLogin:active {
  background-color: #3d3b80 !important;
}

.login-form {
  width: 22rem !important;
  max-width: 100% !important;
}

@media (max-width: 700px) {
  .login-form {
    width: 40rem;
    max-width: 100%;
    background-color: white;
    margin: 0 auto;
    padding: 20px;
  }
}
